import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import About from "./components/about";
import Services from "./components/services";
import Home from "./components/homepage";
import Rabout from "./components/rabout";
import { Redirect } from "react-router-dom";
const BaseRouter = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/Services">
      <Services />
    </Route>
    <Route exact path="/About">
      <About />
    </Route>
    <Route exact path="/rabout">
      <Rabout />
    </Route>
    <Route render={() => <Redirect to={{ pathname: "/" }} />} />
    {/* <Route exact path="/upload"><Upload/></Route> */}
  </Switch>
);
export default BaseRouter;
