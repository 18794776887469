import React, { useState } from "react";
import "../css/appbar.css";
import { Container } from "@material-ui/core";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "../routes";

const Appbar = (props) => {
  const myFunction = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };
  return (
    <Router>
      <div className="topnav" id="myTopnav">
        <NavLink to="/" exact={true}>
          <div className="logo_"></div>
        </NavLink>

        <NavLink exact={true} to="/About" id="con" activeClassName="active">
          Contact Us
        </NavLink>

        <NavLink exact={true} to="/Services" activeClassName="active">
          Services
        </NavLink>
        <NavLink exact={true} to="/rabout" activeClassName="active">
          About Us
        </NavLink>
        <NavLink exact={true} to="/" activeClassName="active">
          Home
        </NavLink>
        <a href="javascript:void(0);" className="icon" onClick={myFunction}>
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <BaseRouter />
    </Router>
  );
};

export default Appbar;
