import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// function Greeting() {
//   var d = new Date();
//   var month = d.getMonth() + 1;
//   var year = 2020;
//   var date = d.getDate();
//   if (
//     (month == 9 && year == 2020) ||
//     (month == 10 && year == 2020 && date <= 5)
//   ) {
//     return (
//       <div className="coming_">
//         <img src="/final.png" />
//         <h1>Coming Soon</h1>
//       </div>
//     );
//   } else {
//     return <App />;
//   }
// }
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
