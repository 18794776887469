import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import "../css/rabout.css";
import { Container } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Rabout() {
  const classes = useStyles();
  const history = useHistory();
  const onClick = () => history.push("/About");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={3} className={classes.paper}>
          <Grid item xs={12}>
            <div className="intro">
              <h1>ABOUT US</h1>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={8} className="max">
          <Grid item xs={12}>
            <div className="hov1">
              <h2>We are a main London-based accounting firm </h2>
              <p>
                We are a main London-based accounting firm and our team has been
                changing the lives of entrepreneurs, improving profits, helping
                businesses grow and adding value to businesses. We believe in
                proactive approach to help startups to grow. We are energetic
                about helping business visionaries convert their thoughts into
                real factors and enhance the interesting universe of new
                companies.
              </p>
            </div>
            <div className="mix1"></div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "6%" }}>
            <div className="hov2">
              <h2>We strive to achieve perfection </h2>
              <p>
                We aim to improve the client experience, quicken development,
                enhance cost effectiveness, and oversee threat. From our point
                of view, management is a guarantee, and responsibility is an
                excellence. Whilst being dedicated to development, we realise
                the optimum approach to expand our business is by adequately and
                capably securing yours. Make proper acquaintance with what is to
                come!
              </p>
            </div>
            <div className="mix2"></div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" className="padd_">
        <Grid container className="max">
          <Grid item xs={12} style={{ padding: "0 0 7% 0" }}>
            <div style={{ textAlign: "center" }}>
              <h1>Meet our leadership team 👋</h1>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} style={{ padding: "0 3%" }}>
            <div className="tareef">
              <h3>Creator</h3>
              <h2>Mohammad Arslan</h2>
              <p>
                Mohammad has vast experience in Accountancy, Finance and Tax,
                who has been working with SMEs, Sole traders, and Landlords for
                over 10 years. I have gained experience while working and
                advising broad range of businesses and private individuals. My
                accountancy practice is embedded with forward thinking and
                understanding clients’ goals approach and their expertise are
                providing regular support in all aspects of accountancy, tax and
                business advisory services.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="shashka"></div>
          </Grid>
          <Grid item xs={12} style={{ padding: "3% 0" }}>
            <div className="startup">
              <h1>HOW CAN WE HELP? </h1>
              <p>
                You might want to select a subject underneath identified with
                your request. If you do not discover what you need, please feel
                free to contact us.
              </p>
              <button className="revamp" onClick={onClick}>
                LEARN MORE
              </button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
