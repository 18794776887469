import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchAppBar from "./components/appbar";
import Home from "./components/homepage";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import Services from "./components/services";
import About from "./components/about";
import BaseRouter from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import SliderExample from "./components/carousel";

function App() {
  return (
    <div className="App">
      <SearchAppBar />

      <footer>
        <Grid container style={{ padding: "0 2%" }}>
          <Grid item xs={12} sm={5}>
            <div className="footBox">
              <h2>Copyright &#169; 2020 Book Smart Services Ltd.</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="footBox"></div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="footBox3">
              <h2>Subscribe to our updates</h2>
              <p>
                Sign up with your email address to receive news and updates.
              </p>
              <div className="form2">
                <input class="i1" type="text" placeholder="Enter Your Email" />

                <button className="revamp">Signup</button>
              </div>

              <p>
                We respect your privacy we’ll never share your email with anyone
                else.
              </p>
            </div>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}

export default App;
