import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import "../css/homepage.css";
import { Container } from "@material-ui/core";
import SliderExample from "./carousel";
import { Fade, Slide } from "react-awesome-reveal";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pic1: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  Text1: {
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(2),
  },
  t1: {
    padding: theme.spacing(7),
    textAlign: "center",
  },
}));

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const history = useHistory();
  const onClick = () => history.push("/About");
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} style={{ padding: 0 }}>
          <div className="pic">
            <div className="header">
              <div>
                <h1>ACCOUNTANTS, TAX ADVISERS & BUSINESS CONSULTANTS</h1>
                <p>
                  Book Smart Services Ltd is a firm of Accountants located in
                  Ilford. What makes us unique is that we do not just provide
                  mainstream services, but we make sure to provide regular
                  support, understand clients’ goals and present opportunities
                  to make sure they achieve them.
                </p>
                <a href="#">Contact us</a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={3} className={classes.paper}>
          <Grid item xs={12}>
            <Slide>
              <div className={classes.Text1}>
                <div className="para">
                  <h2>Services from expert accountants for startups</h2>
                  <p>
                    We are a main London-based accounting firm and our team has
                    been changing the lives of entrepreneurs, improving profits,
                    helping businesses grow and adding value to businesses. We
                    believe in proactive approach to help startups to grow. We
                    are energetic about helping business visionaries convert
                    their thoughts into real factors and enhance the interesting
                    universe of new companies.
                  </p>
                </div>
              </div>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Slide>
              <div className={classes.paper}>
                <h2>Determination</h2>
                <p>
                  Our core belief is to help their businesses grow and to change
                  their lives. We are a technology driven accountancy firm and
                  our motive is to keep our office environmentally friendly.
                </p>
                <button className="revamp" onClick={onClick}>
                  LEARN MORE
                </button>
              </div>
            </Slide>
          </Grid>
          <Grid item xs={12} sm={6} className="pictures">
            <div className={classes.paper}>
              <Slide direction="right">
                <img
                  src="https://dailytimes.com.pk/assets/uploads/2019/02/12/success-1280x720.jpg"
                  alt="pic1"
                  height="300px"
                  width="auto"
                />
              </Slide>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="pictures">
            <div className={classes.paper}>
              <Slide>
                <img
                  src="https://www.mycustomer.com/sites/default/files/styles/inline_banner/public/six_stars_1_1.jpg?itok=MLCP1hba"
                  alt="pic1"
                  className="pic_"
                />
              </Slide>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Slide direction="right">
              <div className={classes.paper}>
                <h2>Quality Service</h2>
                <p>
                  We make sure to provide quality, proactive and friendly
                  professional services to our client. Our services include
                  accountancy, personal tax advice, tax planning, corporate tax,
                  VAT, outsourced accounting, payroll, bookkeeping and corporate
                  finance.
                </p>
                <button className="revamp" onClick={onClick}>
                  LEARN MORE
                </button>
              </div>
            </Slide>
          </Grid>
        </Grid>
        <Fade>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <div className={classes.t1}>
                <h1>What our clients say about us 👇</h1>{" "}
              </div>
            </Grid>
          </Grid>
        </Fade>
        <Grid container spacing={3} className={classes.paper}>
          <Grid item xs={12}>
            <div className="contain">
              <SliderExample />
            </div>
          </Grid>
        </Grid>
        <Fade>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <div className="startup">
                <h1>CERTIFIED BY </h1>
                <img src="/logonew.jpg" className="pic_"></img>
                <img src="/ACCA-Logo.jpg" className="pic1_"></img>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="startup">
                <h1>HOW CAN WE HELP? </h1>
                <p>
                  You might want to select a subject underneath identified with
                  your request. If you do not discover what you need, please
                  feel free to contact us.
                </p>
                <button className="revamp" onClick={onClick}>
                  LEARN MORE
                </button>
              </div>
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </div>
  );
}
