import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import "../css/about.css";
import { Container, TextareaAutosize } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Fade, Slide } from "react-awesome-reveal";
import { useHistory, Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function About() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const onClick = () => history.push("/About");
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container style={{ padding: "5% 0" }}>
          <Grid item xs={12}>
            <div className="contact">
              <h1>Let’s work together</h1>
              <p>
                While we're acceptable with smoke signals, there are easier ways
                for us to connect and address your inquiries. We anticipate
                getting with you.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "2% 0" }}>
          <Grid item xs={12}>
            <div className="con">
              <h3>EMAIL US</h3>
              <a>talk@booksmartservices.co.uk</a>
              <h3>RING US</h3>
              <a>020 3011 4343</a>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "2% 0" }}>
          <Grid item xs={12}>
            <div className="form1">
              <h3>REQUEST A CALLBACK FROM THE TEAM</h3>
              <form>
                <h2>Company Name</h2>
                <label>To tell the company name</label>

                <div class="col-3">
                  <input
                    class="effect-8"
                    type="text"
                    placeholder="Enter here"
                  />
                  <span class="focus-border">
                    <i></i>
                  </span>
                </div>
                <h2>Phone Number</h2>
                <label>Enter phone no</label>

                <div class="col-3">
                  <input
                    class="effect-8"
                    type="text"
                    placeholder="Enter Here"
                  />
                  <span class="focus-border">
                    <i></i>
                  </span>
                </div>
                <h2>Some Comments</h2>
                <label>Share Your feelings</label>

                <div class="col-3">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Enter Here"
                    rowsMin={6}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <button className="revamp">SEND</button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="sm">
        <Grid container spacing={6} style={{ padding: "5% 0" }}>
          <Grid item xs={12}>
            <div className="senter">
              <h3>Our Address</h3>

              <a>Olympic House, 28-42 Clements Road, Ilford, Essex IG1 1BA</a>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* <Container>
        <Fade>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <div className="startup">
                <h1>HOW CAN WE HELP? </h1>
                <p>
                  You might want to select a subject underneath identified with
                  your request. In the event that you don't discover what you
                  need, round out our contact structure.
                </p>
                <button className="revamp" onClick={onClick}>
                  LEARN MORE
                </button>
              </div>
            </Grid>
          </Grid>
        </Fade>
      </Container> */}
    </div>
  );
}
