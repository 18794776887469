import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import "../css/services.css";
import { Container } from "@material-ui/core";
import SliderExample from "./carousel";
import { GiSupersonicArrow } from "react-icons/gi";
import { FaBox, FaScroll } from "react-icons/fa";
import { BiNetworkChart, BiBarChartAlt2 } from "react-icons/bi";
import { BsBookHalf } from "react-icons/bs";
import { RiBuildingLine } from "react-icons/ri";
import { BsCalendar } from "react-icons/bs";

import { MdSupervisorAccount, MdAccountBalance } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { TiBusinessCard } from "react-icons/ti";
import { IconContext } from "react-icons";
import { sizing } from "@material-ui/system";
import { useHistory, NavLink, BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "../routes";
import Appbar from "./appbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Services() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const onClick = () => history.push("/About");
  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <div className="cont">
              <h1>Services</h1>
              <p>
                Book Smart Services Ltd offers a full range of accountancy, tax,
                VAT and company secretarial services. We view all the work that
                we do for you as a prospect to improve your financial
                performance and profitability.When collaborating with an
                organisation like ours, you gain admittance to top level ability
                and can use the organisation's inner skills obtained through the
                conveyance of numerous ventures.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className="masterbox">
          <Grid item xs={12} sm={6} className="box1">
            <div className="vlog">
              <p>
                We can extend the aptitudes set of your business with
                specialists who represent considerable authority in explicit
                regions or front-line advances. This administration is ideal for
                organisations hoping to help their cycles with additional
                ability and keep up their force in business development.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="box1">
            <div className="vlog">
              <p>
                Our specialists help corporate customers to keep awake to date
                with current business digitisation arrangements and assist them
                with accomplishing up to half beginning phase advancement, on
                account of our help.
              </p>
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "5%" }}>
            <div style={{ textAlign: "center" }}>
              <h1>Services We Provide</h1>{" "}
            </div>
          </Grid>
          <div className="row_">
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <MdAccountBalance />
                </div>
              </IconContext.Provider>
              <div className="ptext">
                <p>Accountancy</p>
              </div>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <TiBusinessCard />
                </div>
              </IconContext.Provider>
              <div className="ptext">
                <p>personal tax advice</p>
              </div>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <BsCalendar />
                </div>
                <div className="ptext">
                  <p>tax planning</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <RiBuildingLine />
                </div>
                <div className="ptext">
                  <p>corporate tax</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <FaBox />
                </div>
                <div className="ptext">
                  <p>VAT</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <BiNetworkChart />
                </div>
                <div className="ptext">
                  <p>outsourced accounting</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <AiOutlineFileDone />
                </div>
                <div className="ptext">
                  <p>payroll</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <BsBookHalf />
                </div>
                <div className="ptext">
                  <p>bookkeeping</p>
                </div>
              </IconContext.Provider>
            </div>
            <div className="pservice">
              <IconContext.Provider value={{ className: "icon_2" }}>
                <div id="size_2">
                  <BiBarChartAlt2 />
                </div>
                <div className="ptext">
                  <p>corporate finance</p>
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </Grid>
      </Container>
      <Grid container style={{ padding: "5% 0" }}>
        <Grid item xs={12}>
          <div className="original-div">
            <div className="back-div">
              <div className="overlay-div">
                <h1>WHY US?</h1>
                <p>
                  We work for clients, not quarterly outcomes. This permits us
                  to be responsive, adaptable, and client centered. The
                  objectives we set for our organisation consistently
                  contemplate the best long-haul enthusiasm of our clients
                  instead of the transient enthusiasm of money related weights.
                </p>
                <p>
                  We guarantee that our clients are educated and acutely mindful
                  of key operational activities occurring inside their
                  condition. The key advantage to our clients is far reaching
                  data sharing and precise help. <br />
                </p>
                <p>
                  {" "}
                  By selecting Book Smart Services Ltd we promise to deliver:
                </p>
                <ul>
                  <li>Gain time to focus on core business functions</li>
                  <li>
                    practical: we don't just 'respond', we work together with
                    you to help you progress
                  </li>
                  <li>
                    value-added: we're dedicated to helping you grow, not just
                    by keeping you compliant
                  </li>
                  <li>
                    prompt: your business is our priority, which means you get a
                    reply when you need it
                  </li>
                  <li>
                    Expert: our team of professionals is supported by the vast
                    experience of our partners
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={3} style={{ padding: "5% 0" }}>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <h1>What our clients say about us 👇</h1>{" "}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={3} style={{ padding: "5% 1%" }}>
        <Grid item xs={12}>
          <div className="contain">
            <SliderExample />
          </div>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={3} style={{ padding: "5% 5%" }}>
          <Grid item xs={12}>
            <div className="startup">
              <h1>CONTACT US </h1>
              <p>
                You might want to select a subject underneath identified with
                your request. If you do not discover what you need, please feel
                free to contact us.
              </p>
              <button className="revamp" onClick={onClick}>
                LEARN MORE
              </button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
