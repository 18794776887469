import React from "react";
import Slider from "react-slick";
import "../css/carousel.css";
import Grid from "@material-ui/core/Grid";
import ReactStars from "react-rating-stars-component";
export default class SliderExample extends React.Component {
  render() {
    let settings = {
      dots: true,
      className: "sample",
      speed: 300,
      infinite: true,

      autoplay: true,
      autoplaySpeed: 3000,
    };
    return (
      <Slider {...settings}>
        <div className="eff">
          <Grid container spacing={3} style={{ padding: "5% 5%" }}>
            <Grid xs={12}>
              <div className="text_">
                <h1>Qasim R</h1>
                <p>
                  Book Smart Services Ltd have been doing my Accounts and Tax
                  Returns for the last four years now. They've been very
                  professional and even give free advice in many other matters.
                  They also charge very reasonable fees for their services. I
                  highly recommend them for your Accounting needs.
                </p>
              </div>
              <ReactStars
                count={5}
                value={5}
                edit={false}
                size={30}
                activeColor="#ffd700"
                classNames="stars"
              />
            </Grid>
          </Grid>
        </div>
        <div className="eff">
          <Grid container spacing={3} style={{ padding: "5% 5%" }}>
            <Grid xs={12}>
              <div className="text_">
                <h1>RavinderS-33</h1>
                <p>
                  The owner (Mohammad Arslan) provided excellent advice and
                  service with quick turnaround. He did excellent job on my
                  self-assessment including Employment income, dividend and
                  property accounts along with LTD company accounts including
                  VAT. I needed accounts done quickly for mortgage application
                  and Book Smart Service did just that!! Recommend..
                </p>
                <p>
                  {" "}
                  <ReactStars
                    count={5}
                    value={5}
                    edit={false}
                    size={30}
                    activeColor="#ffd700"
                    classNames="stars"
                  />
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Slider>
    );
  }
}
